import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, SubTopic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { ValueChainMap } from '../elements/value-chain-map';
import { classNames } from '@shapeable/utils';
const cls = classNames('subtopic-Aside-layout');

// -------- Types -------->

export type SubTopicAsideLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicAsideLayoutDefaultProps: Omit<SubTopicAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(3)};
  `,
});

const MapStyles = breakpoints({
  base: css`
    max-width: 100%;
    height: auto;
    margin-top: ${theme.UNIT(2)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Map: styled(ValueChainMap)`${MapStyles}`,
};

export const SubTopicAsideLayout: Shapeable.FC<SubTopicAsideLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Map />
    </My.Container>
  )
};

SubTopicAsideLayout.defaultProps = SubTopicAsideLayoutDefaultProps;
SubTopicAsideLayout.cls = cls;